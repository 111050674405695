<template>
  <notifications
    :ignore-duplicates="true"
    animation-type="velocity"
    :position="position"
    v-bind="[$attrs, $props]">
    <template slot="body" slot-scope="{ item, close }">
      <div class="app-notification" :class="item.data.appClasses">
        <v-icon :color="item.data.iconColor" size="20px">{{ item.data.icon }}</v-icon>
        <div class="flex-1">
          <m-stack gap="spacing-1">
            <m-text variant="body-md-bold" v-if="item.title">
              <strong>{{ item.title }}</strong>
            </m-text>
            <m-text variant="body-sm" v-html="item.text"></m-text>
          </m-stack>
        </div>

        <component
          v-if="item.data.actionFn"
          :is="item.data.actionComponent"
          :test-id="getTestId(item, 'action-btn')"
          @click="item.data.actionFn">
          {{ item.data.actionLabel ?? 'test' }}
        </component>

        <button class="close" @click="close" :data-testId="getTestId(item, 'close-btn')">
          <v-icon size="20px">mdi-close</v-icon>
        </button>
      </div>
    </template>
  </notifications>
</template>

<script>
import {
  VIcon,
  ErrorButton,
  TertiaryButton,
  WarningButton,
  PrimaryButton
} from '@satellite/components/v2';
import { toKebabCase } from '@satellite/../nova/core';

/**
 * App snackbar-style notifications that wraps the Vue Notification lib.
 *
 * For options and other info, check out the `notify` method inside utilityMixinBase.js in Satellite
 * @displayName App Notification
 */
export default {
  name: 'AppNotification',
  components: { VIcon, ErrorButton, PrimaryButton, WarningButton, TertiaryButton },
  props: {
    /**
     * Position of the snackbar
     */
    position: {
      type: String,
      required: false,
      default: 'bottom right'
    }
  },
  methods: {
    getTestId(item, postFix) {
      return `${toKebabCase(item.text ?? item.title)}-${postFix}`;
    }
  }
};
</script>

<style lang="scss">
.v-application {
  .vue-notification-group {
    width: 500px !important;
    max-width: calc(100% - 2rem);
  }

  .vue-notification-wrapper {
    height: auto !important;
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  .app-notification {
    border-radius: 5px;
    position: relative;
    padding: $m-spacing-4;
    display: flex;
    align-items: center;
    gap: $m-spacing-4;

    &.app-success {
      background-color: $m-color-success-20 !important;
      border-bottom: 3px solid $m-color-success-60 !important;
      color: #000;
    }

    &.app-error {
      background-color: $m-color-danger-20 !important;
      border-bottom: 3px solid $m-color-danger-60 !important;
      color: #000;

      i.type-icon {
        color: $m-color-danger-60 !important;
      }
    }

    &.app-warning {
      background-color: $m-color-warning-20;
      border-bottom: 3px solid $m-color-warning-60 !important;
      color: #000;
    }

    &.app-info {
      background-color: $m-color-accent-20 !important;
      border-bottom: 3px solid $m-color-accent-60 !important;
      color: #000;
    }

    .title {
      font-size: 1rem !important;
      margin-bottom: 0;
      line-height: 1.25rem;
    }
  }
}
</style>
