<template>
  <m-field
    :data-testid="testId"
    class="field-wrapper"
    :status="hasError ? 'danger' : 'default'"
    :class="`multi-drop-down-${_uid}`">
    <m-field-label slot="label" v-if="label">{{ label }}</m-field-label>
    <vuetify-select
      :attach="`.multi-drop-down-${_uid}`"
      multiple
      :items="options"
      v-bind="[$attrs, $props]"
      hide-details="hide"
      label=" "
      item-text="label"
      item-value="value"
      :value="value"
      :style="computedWidth"
      :data-testid="`${testId}-trigger`"
      :menu-props="{
        bottom: true,
        offsetY: true,
        rounded: true,
        ripple: false
      }"
      @input="val => (internalValue = val)">
      <template #append>
        <v-icon size="19px" color="color-text-tertiary">chevron-down</v-icon>
      </template>
      <template #item="{ item, on }">
        <m-dropdown-item
          :class="{ selected: isSelected(item) }"
          :data-testid="`${testId}-menu-item`">
          {{ item.label }}
          <v-icon v-if="isSelected(item)">check</v-icon>
        </m-dropdown-item>
      </template>
    </vuetify-select>
    <m-field-hint :data-testid="`${testId}-errors`" v-if="shouldDisplayMessages">
      <span>{{ errorBucket.join(', ') }}</span>
      <span v-if="errorBucket.length && hint">.</span>
      <span v-if="hint" :class="{ 'p-l-1': errorBucket.length }">{{ hint }}</span>
    </m-field-hint>
  </m-field>
</template>

<script>
import { isEqual } from 'lodash';
import fieldMixin from '@satellite/components/v2/mixins/fieldMixin';
import validatable from '@satellite/components/v2/mixins/validatable';
import testable from '@satellite/components/v2/mixins/testable';
import { VIcon } from '@satellite/components/v2';
import { VSelect as VuetifySelect } from 'vuetify/lib/components/VSelect';
import { validateV2DropDownOptionsProp } from '@satellite/plugins/util';

/**
 * DropDownField wraps Vuetify V2 library's <v-select>
 *   Keeping separate from Drop Down Field because it's Miranda
 * @displayName Multi Drop Down Field
 */
export default {
  name: 'MultiDropDownField',
  mixins: [fieldMixin, validatable, testable],
  components: { VuetifySelect, VIcon },
  props: {
    /**
     * null type to allow "any"
     * @model
     */
    value: {
      type: null,
      required: false
    },
    /**
     * Field options
     */
    options: {
      type: Array,
      required: false,
      default() {
        return [];
      },
      validator(value) {
        return validateV2DropDownOptionsProp(value, 'MultiDropDownField');
      }
    },
    /**
     * Placement of the open drop down box
     */
    placement: {
      type: String,
      required: false,
      default: 'bottom-start'
    },
    /**
     * Position of the open drop down box
     */
    position: {
      type: String,
      required: false,
      default: 'absolute'
    }
  },
  methods: {
    isSelected(option) {
      return _.some(this.value, val => isEqual(option?.value ?? option, val));
    }
  }
};
</script>

<style lang="scss" scoped>
.field-wrapper {
  flex: 1;
  position: relative;
}
.dropdown-menu {
  max-height: 10rem;
  width: 100%;
}
.select-option {
  justify-content: space-between;
}
.selected {
  background-color: $m-color-background-highlight;
  font-weight: $m-font-weight-bold;
}

m-dropdown-item {
  width: 100%;
  justify-content: space-between;
}

::v-deep {
  .v-input {
    padding: 0;
    margin: 0;
  }
  .v-input__slot {
    &:before,
    &:after {
      display: none;
    }
  }

  .v-select__slot {
    min-height: 40px !important;
    max-height: 40px !important;
    --m-dropdown-trigger-background-color: rgba(
      var(--m-color-background-primary, 252, 252, 255),
      1
    );
    --m-dropdown-trigger-border-color: rgba(var(--m-color-border, 193, 206, 217), 1);
    --m-dropdown-trigger-border-radius: var(--m-border-radius-s, 4px);
    --m-dropdown-trigger-color: rgba(var(--m-color-text-tertiary, 92, 106, 118), 1);
    --m-dropdown-trigger-padding-x: var(--m-spacing-2, 8px);
    --m-dropdown-trigger-padding-y: var(--m-spacing-2, 8px);
    --m-dropdown-trigger-height: var(--m-global-height-default, 2.5rem);
    transition: color 0.35s ease-in-out 0s, background-color 0.35s ease-in-out 0s,
      border-color 0.35s ease-in-out 0s;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    font-family: var(--m-font-family-default, 'Manrope', sans-serif);
    line-height: var(--m-line-height-5, 150%);
    font-size: var(--m-font-size-3, 0.875rem);
    font-weight: var(--m-font-weight-regular, 500);
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    block-size: var(--m-dropdown-trigger-height);
    inline-size: 100%;
    box-sizing: border-box;
    text-decoration: none;
    gap: var(--m-spacing-1, 4px);
    padding-inline-end: var(--m-dropdown-trigger-padding-x);
    padding-inline-start: var(--m-dropdown-trigger-padding-x);
    padding-block-start: var(--m-dropdown-trigger-padding-y);
    padding-block-end: var(--m-dropdown-trigger-padding-y);
    background-color: var(--m-dropdown-trigger-background-color);
    color: var(--m-dropdown-trigger-color);
    border: 1px solid var(--m-dropdown-trigger-border-color);
    border-radius: var(--m-dropdown-trigger-border-radius);
    outline: none;
  }
  .v-input__append-inner {
    margin-top: $m-spacing-0-5 !important;
    margin-right: -2px;
  }

  .v-list-item {
    padding: 0 $m-spacing-2;
    min-height: auto;
    &.v-list-item--highlighted {
      background-color: $m-color-background-primary;
    }
  }

  .v-list-item__content {
    padding: 0;
  }

  .v-list-item--link:before {
    display: none;
  }

  .v-list-item--active {
    .v-list-item__title {
      background: $m-color-background-tertiary;
    }
  }

  .v-list-item__title {
    padding: 0 $m-spacing-1 !important;
    --m-dropdown-item-background-color: rgba(var(--m-color-background-primary, 252, 252, 255), 1);
    --m-dropdown-item-color: rgba(var(--m-color-text-secondary, 61, 74, 85), 1);
    transition: color 0.35s ease-in-out 0s, background-color 0.35s ease-in-out 0s;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    font-family: var(--m-font-family-default, 'Manrope', sans-serif);
    line-height: var(--m-line-height-5, 150%);
    font-size: var(--m-font-size-3, 0.875rem);
    font-weight: var(--m-font-weight-regular, 500);
    block-size: 36px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    gap: var(--m-spacing-2, 8px);
    padding-inline-end: var(--m-spacing-1, 4px);
    padding-inline-start: var(--m-spacing-1, 4px);
    padding-block-start: var(--m-spacing-2, 8px);
    padding-block-end: var(--m-spacing-2, 8px);
    background-color: var(--m-dropdown-item-background-color);
    color: var(--m-dropdown-item-color);
    border: none;
    border-radius: var(--m-border-radius-s, 4px);
  }

  .v-ripple__container {
    display: none !important;
  }

  .v-list-item__action {
    display: none;
  }

  .v-menu__content {
    transform: translateY(25px);
  }
}
</style>
