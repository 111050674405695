var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.hasCustomFormData
        ? _c(
            "m-grid",
            _vm._b({ staticClass: "value-grid" }, "m-grid", _vm.$attrs, false),
            _vm._l(_vm.customFormData, function ({ id, label, value }) {
              return _c("field-value", {
                key: id,
                attrs: { label: label, value: value },
              })
            }),
            1
          )
        : _c(
            "m-text",
            { attrs: { variant: "body-md", color: "color-text-tertiary" } },
            [_vm._v("Not Confirmed")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }