/**
 * Please note that the order matters here!  e.g. If ButtonBase uses VIcon, VIcon must come before or
 * Vue will throw an error that it cannot read undefined of "default".  If you see that error right after
 * adding to this file, chances are the new component export needs to be placed differently.
 */

/**
 * BASIC COMPONENTS
 */
import VLink from '@satellite/components/v2/elements/basic/VLink.vue';
import VIcon from '@satellite/components/v2/elements/basic/VIcon.vue';
import VSpacer from '@satellite/components/v2/elements/basic/VSpacer.vue';
import VLoader from '@satellite/components/v2/elements/basic/VLoader.vue';
import NoContent from '@satellite/components/v2/elements/basic/NoContent.vue';
import ImageMessage from '@satellite/components/v2/elements/basic/ImageMessage.vue';
import AppointmentStatusTag from '@satellite/components/v2/elements/basic/AppointmentStatusTag.vue';

/**
 * BRANDING COMPONENTS
 */
import AuthLinks from '@satellite/components/v2/elements/branding/AuthLinks.vue';
import CopyrightText from '@satellite/components/v2/elements/branding/CopyrightText.vue';
import VLogo from '@satellite/components/v2/elements/branding/VLogo.vue';

/**
 * BUTTON COMPONENTS
 */
import ButtonBase from '@satellite/components/v2/elements/buttons/ButtonBase.vue';
import PrimaryButton from '@satellite/components/v2/elements/buttons/PrimaryButton.vue';
import SecondaryButton from '@satellite/components/v2/elements/buttons/SecondaryButton.vue';
import TertiaryButton from '@satellite/components/v2/elements/buttons/TertiaryButton.vue';
import IconButton from '@satellite/components/v2/elements/buttons/IconButton.vue';
import ErrorButton from '@satellite/components/v2/elements/buttons/ErrorButton.vue';
import WarningButton from '@satellite/components/v2/elements/buttons/WarningButton.vue';
import LinkButton from '@satellite/components/v2/elements/buttons/LinkButton.vue';
import SidebarButton from '@satellite/components/v2/elements/buttons/SidebarButton.vue';
import CopyContentButton from '@satellite/components/v2/elements/buttons/CopyContentButton.vue';
import ButtonToggleGroup from '@satellite/components/v2/elements/buttons/ButtonToggleGroup.vue';

/**
 * STEPPER COMPONENTS
 */
import StepperNav from '@satellite/components/v2/elements/steppers/StepperNav.vue';
import VStepper from '@satellite/components/v2/elements/steppers/VStepper.vue';

/**
 * SEARCH COMPONENTS
 */
import NoResults from '@satellite/components/v2/elements/search/NoResults.vue';

/**
 * SNACKBAR COMPONENTS
 */
import AppNotification from '@satellite/components/v2/elements/snackbars/AppNotification.vue';

/**
 * DATA COMPONENTS
 */
import FieldValue from '@satellite/components/v2/elements/data/FieldValue.vue';
import CustomFieldValue from '@satellite/components/v2/elements/data/CustomFieldValue.vue';
import BigNumberBoxes from '@satellite/components/v2/elements/data/BigNumberBoxes.vue';

/**
 * FORM COMPONENTS
 */
import MultiChipInput from '@satellite/components/v2/elements/form/MultiChipInput.vue';
import TextField from '@satellite/components/v2/elements/form/TextField.vue';
import PasswordField from '@satellite/components/v2/elements/form/PasswordField.vue';
import DropDownField from '@satellite/components/v2/elements/form/DropDownField.vue';
import TextArea from '@satellite/components/v2/elements/form/TextArea.vue';
import SingleDatePicker from '@satellite/components/v2/elements/form/SingleDatePicker.vue';
import VSwitch from '@satellite/components/v2/elements/form/VSwitch.vue';
import PhoneNumberField from '@satellite/components/v2/elements/form/PhoneNumberField.vue';
import TimeStampField from '@satellite/components/v2/elements/form/TimeStampField.vue';
import MultiDropDownField from '@satellite/components/v2/elements/form/MultiDropDownField.vue';
import ComboBox from '@satellite/components/v2/elements/form/ComboBox.vue';
import DocumentUpload from '@satellite/components/v2/elements/form/DocumentUpload.vue';

// THIS ALWAYS GOES LAST OUT OF THE FORM COMPONENTS SO WE CAN USE THE FORM FIELDS ABOVE
import BasicFormField from '@satellite/components/v2/elements/form/BasicFormField.vue';

/**
 * TABLE COMPONENTS
 */
import DataTableBase from '@satellite/components/v2/elements/tables/DataTableBase.vue';

/**
 * LIST COMPONENTS
 */
import HoursOfOperationList from '@satellite/components/v2/elements/lists/HoursOfOperationList.vue';
import VPagination from '@satellite/components/v2/elements/lists/VPagination.vue';

/**
 * CARD COMPONENTS
 */
import CardGrid from '@satellite/components/v2/elements/cards/CardGrid.vue';
import CollapsableCard from '@satellite/components/v2/elements/cards/CollapsableCard.vue';

/**
 * FORMS
 */
import BasicForm from '@satellite/components/v2/elements/form/BasicForm.vue';
import DateRangePicker from '@satellite/components/v2/elements/form/DateRangePicker.vue';

/**
 * ADVANCED BUTTON COMPONENTS
 */
import DateNav from '@satellite/components/v2/elements/buttons/docs/DateNav.vue';

/**
 * DIALOG COMPONENTS
 */
import DialogBase from '@satellite/components/v2/elements/dialogs/DialogBase.vue';

/**
 * LAYOUT COMPONENTS
 */
import PageHeader from '@satellite/components/v2/elements/layout/PageHeader.vue';
import InternalPage from '@satellite/components/v2/elements/layout/InternalPage.vue';
import SidebarCards from '@satellite/components/v2/elements/layout/SidebarCards.vue';

export {
  AppNotification,
  AuthLinks,
  BasicForm,
  BasicFormField,
  ButtonBase,
  ButtonToggleGroup,
  CardGrid,
  CollapsableCard,
  ComboBox,
  CopyContentButton,
  CopyrightText,
  CustomFieldValue,
  DataTableBase,
  DateNav,
  DialogBase,
  DocumentUpload,
  DropDownField,
  ErrorButton,
  FieldValue,
  HoursOfOperationList,
  IconButton,
  ImageMessage,
  InternalPage,
  LinkButton,
  MultiChipInput,
  MultiDropDownField,
  NoContent,
  NoResults,
  PageHeader,
  PasswordField,
  PhoneNumberField,
  PrimaryButton,
  SecondaryButton,
  SidebarButton,
  SidebarCards,
  SingleDatePicker,
  StepperNav,
  TertiaryButton,
  TextArea,
  TextField,
  TimeStampField,
  VIcon,
  VLink,
  VLoader,
  VLogo,
  VPagination,
  VSpacer,
  VStepper,
  VSwitch,
  WarningButton,
  BigNumberBoxes,
  AppointmentStatusTag,
  DateRangePicker
};
