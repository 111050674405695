<template>
  <dialog-base v-bind="$attrs" :value="value" @input="updateVal" size="medium">
    <template #header>
      <m-text variant="heading-md-bold">Provide ETA</m-text>
    </template>
    <template #body>
      <m-stack gap="spacing-3" v-if="!isLoading">
        <m-text variant="heading-sm-bold">
          Appointment time: {{ extendedAppointment.getReadableDate() }} -
          {{ extendedAppointment.getReadableStartTime() }}
          {{ extendedAppointment.getTimezoneAbbreviation() }}
        </m-text>
        <m-group class="flex-nowrap p-r-20" gap="spacing-2">
          <v-icon size="20px" color="color-text-tertiary">alert</v-icon>
          <m-text variant="body-sm" color="color-text-tertiary">
            Providing an ETA notifies the warehouse but does not reschedule the appointment or
            bypass late charges and fines
          </m-text>
        </m-group>
        <m-group gap="spacing-2" align="center">
          <v-icon size="20px" color="color-text-tertiary">clock-outline</v-icon>
          <m-text variant="body-sm" color="color-text-tertiary">
            ETA must be within {{ novaCore.ETA_WINDOW_HOURS }} hours of the appointment start time
          </m-text>
        </m-group>
        <m-group class="flex-nowrap eta-input" align="center">
          <time-stamp-field
            required
            :min-date="minDate"
            :max-date="maxDate"
            test-id="eta-time-stamp"
            should-validate
            v-model="etaISO"
            :validation-iterator="validationIterator"
            label="ETA"
            :is-military-time-enabled="extendedAppointment.isMilitaryTimeEnabled"
            :timezone="extendedAppointment.warehouse.timezone"
            placeholder="07:00"
            :rules="[
              ...$validator.rules.required(),
              v =>
                isEtaOnWindow(v, extendedAppointment.start) ||
                `ETA must be within ${novaCore.ETA_WINDOW_HOURS} hours of the appointment start time`
            ]"
            @update:error="hasError => updateFieldError(hasError, 'eta')" />
          <div class="m-t-6 tag-wrapper">
            <m-tag size="large" :variant="etaTagVariant">
              {{ getReadableEtaStatus(etaCondition) }}
            </m-tag>
          </div>
        </m-group>
        <text-field
          test-id="eta-reason"
          v-model="etaReason"
          label="Reason"
          required
          :validation-iterator="validationIterator"
          :rules="$validator.rules.required()"
          @update:error="hasError => updateFieldError(hasError, 'reason')"></text-field>
      </m-stack>
      <v-loader v-else :is-loading="isLoading">Updating the ETA</v-loader>
    </template>
    <template #actions>
      <m-group class="flex-nowrap p-t-4" gap="spacing-2">
        <template>
          <tertiary-button :disabled="isLoading" test-id="cancel-eta-btn" @click="updateVal(false)">
            Cancel
          </tertiary-button>
          <primary-button :disabled="!canUpdateETA" @click="createEta" test-id="create-eta-btn">
            Provide ETA
          </primary-button>
        </template>
      </m-group>
    </template>
  </dialog-base>
</template>

<script>
import {
  DialogBase,
  PrimaryButton,
  TertiaryButton,
  VIcon,
  TimeStampField,
  TextField,
  VLoader
} from '@satellite/components/v2';
import dialogMixin from '@satellite/components/v2/mixins/dialogMixin';
import { ExtendedAppointment } from '@satellite/modules/v2/appointments/ExtendedAppointment';
import { propValidatorHelper } from '@satellite/plugins/util';
import validationMixin from '@satellite/components/v2/mixins/validationMixin';
import { DateTime } from 'luxon';
import {
  getEtaCondition,
  EtaCondition,
  getReadableEtaStatus,
  ETACarrierValidations
} from '@satellite/../nova/core';

export default {
  name: 'CreateEtaDialog',
  mixins: [dialogMixin, validationMixin],
  components: {
    VIcon,
    PrimaryButton,
    TertiaryButton,
    DialogBase,
    TimeStampField,
    TextField,
    VLoader
  },
  props: {
    extendedAppointment: {
      type: ExtendedAppointment,
      required: true,
      validator(value) {
        return propValidatorHelper({
          value,
          isValid: value instanceof ExtendedAppointment,
          componentName: 'CreateEtaDialog',
          propName: 'extendedAppointment',
          message: ''
        });
      }
    }
  },
  computed: {
    isEtaOnWindow() {
      return ETACarrierValidations.isEtaOnWindow;
    },
    etaCondition() {
      return getEtaCondition(
        this.extendedAppointment.start,
        this.etaISO,
        this.extendedAppointment.warehouse.timezone
      );
    },
    canUpdateETA() {
      return (
        !this.isLoading &&
        this.etaReason &&
        this.etaISO &&
        this.isEtaOnWindow(this.extendedAppointment.start, this.etaISO)
      );
    },
    etaTagVariant() {
      let variant;
      switch (this.etaCondition) {
        case EtaCondition.Early:
          variant = 'success';
          break;
        case EtaCondition.Late:
          variant = 'warning';
          break;
        default: {
          variant = 'neutral';
          break;
        }
      }
      return variant;
    }
  },
  data() {
    return {
      etaISO: null,
      etaReason: null,
      isLoading: false,
      minDate: null,
      maxDate: null
    };
  },
  methods: {
    getReadableEtaStatus,
    async createEta() {
      this.validateFields();
      this.$nextTick(async () => {
        if (!this.hasErrors) {
          this.mixpanel.track(this.mixpanel.events.ACTION.PROVIDE_ETA, {
            appointmentId: this.extendedAppointment.id,
            'Warehouse ID': this.extendedAppointment.warehouseId,
            'Warehouse Name': this.extendedAppointment.warehouseName
          });
          this.isLoading = true;
          await this.services.appointment
            .setAppointmentEta(
              this.extendedAppointment.id,
              { etaISO: this.etaISO, etaReason: this.etaReason },
              {},
              { suppressNotification: true }
            )
            .then(appointment => {
              this.$emit('update:appointment', appointment);
              this.notify('Appointment ETA was updated');
            })
            .catch(err => {
              this.notify(`Error when updating the appointment ETA: ${err?.message}`);
            })
            .finally(() => {
              this.isLoading = false;
              this.updateVal(false);
            });
        }
      });
    }
  },
  mounted() {
    const dateTime = DateTime.fromISO(this.extendedAppointment.start, {
      zone: this.extendedAppointment.warehouse.timezone
    });
    this.minDate = dateTime.minus({ hours: 24 }).toISO();
    this.maxDate = dateTime.plus({ hours: 24 }).toISO();
    this.etaISO = this.extendedAppointment.eta ?? this.extendedAppointment.start;
  }
};
</script>

<style lang="scss" scoped>
.tag-wrapper {
  @media (max-width: $mobileBreakpoint) {
    display: none;
  }
}
</style>
