<template>
  <component
    :is="fieldComponent"
    v-bind="fieldProps"
    v-model="fieldProps.value"
    :options="fieldProps.options ?? []"
    :field-name="fieldName"
    :validation-iterator="validationIterator"
    :external-error="fieldProps.error"
    :should-validate="shouldValidate"
    @update:error="emitHasErrors" />
</template>

<script>
// Any component possibilities for the :is prop need to be imported here and registered within this component
import {
  DropDownField,
  TextField,
  PasswordField,
  MultiChipInput,
  TextArea,
  SingleDatePicker,
  VSwitch,
  PhoneNumberField,
  TimeStampField,
  MultiDropDownField,
  ComboBox,
  DocumentUpload
} from '@satellite/components/v2';

/**
 * Component used to render provided fields in the Basic Form component.
 * Can also be used ad-hoc as needed
 * @displayName Basic Form Field
 */
export default {
  name: 'BasicFormField',
  components: {
    DropDownField,
    TextField,
    PasswordField,
    MultiChipInput,
    TextArea,
    SingleDatePicker,
    VSwitch,
    PhoneNumberField,
    TimeStampField,
    MultiDropDownField,
    ComboBox,
    DocumentUpload
  },
  props: {
    /**
     * Iterator to programmatically trigger validation on the field
     */
    validationIterator: {
      type: Number,
      required: false
    },
    /**
     * Used for id/name attributes for the field
     */
    fieldName: {
      type: String,
      required: true
    },
    /**
     * Passthrough props to dynamic component
     */
    fieldProps: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    /**
     * Dynamic component to render
     */
    fieldComponent: {
      type: String,
      required: true
    },
    shouldValidate: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    emitHasErrors(hasErrors) {
      /**
       * @event update:error
       * @property {boolean} hasErrors
       */
      this.$emit('update:error', hasErrors);
    }
  }
};
</script>
