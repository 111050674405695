<template>
  <button-base variant="secondary" :test-id="testId" v-bind="[$attrs]" @click="handleClick($event)">
    <!-- @slot Inner button content -->
    <slot></slot>
  </button-base>
</template>

<script>
import buttonMixin from '@satellite/components/mixins/buttonMixin';
import { ButtonBase } from '@satellite/components/v2';
import testable from '@satellite/components/v2/mixins/testable';

/**
 * Button Base with hard-coded "secondary" variant
 * @displayName Secondary Button
 */
export default {
  name: 'SecondaryButton',
  components: {
    ButtonBase
  },
  mixins: [buttonMixin, testable]
};
</script>
