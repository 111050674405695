<template>
  <basic-form ref="details-form" :fields="detailsFields" @submit="submitForm"></basic-form>
</template>

<script>
import { BasicForm } from '@satellite/components/v2';
import customFieldsMixin from '@satellite/components/v2/mixins/customFieldsMixin';

export default {
  name: 'AppointmentDetailsForm',
  mixins: [customFieldsMixin],
  components: { BasicForm },
  props: {
    appointment: {
      type: Object,
      required: false,
      default: () => null
    },
    customFields: {
      type: Array,
      required: false,
      default: () => []
    },
    warehouse: {
      type: Object,
      required: true
    },
    fieldsAreFullWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      submissionIterator: 0
    };
  },
  computed: {
    isMilitaryTimeEnabled() {
      return this.$isMilitaryTimeEnabled(this.warehouse);
    },
    refNumSettings() {
      return this.$refNumSettings(this.warehouse);
    },
    detailsFields() {
      const fields = {
        refNumber: {
          component: 'text-field',
          fieldProps: {
            label: this.refNumSettings.displayName,
            required: this.refNumSettings.isRequired,
            value: this.appointment?.refNumber ?? '',
            placeholder: this.refNumSettings.helperText || this.refNumSettings.displayName,
            testId: 'ref-num-field'
          }
        },
        ccEmails: {
          component: 'multi-chip-input',
          fieldProps: {
            label: 'Email subscribers',
            required: false,
            itemRules: this.$validator.rules.email,
            value: this.appointment?.ccEmails ?? [],
            placeholder: 'Email subscribers',
            testId: 'email-subscribers-field',
            hint: 'Add email addresses to receive all appointment notitifications',
            hideDetails: true
          }
        },
        notes: {
          component: 'text-area',
          fieldProps: {
            label: 'Appointment notes',
            required: false,
            value: this.appointment?.notes ?? '',
            placeholder: 'Appointment notes',
            testId: 'appointment-notes-field',
            hideDetails: true,
            isCompact: true
          }
        },
        ...this.formattedCustomFields
      };

      if (this.refNumSettings.isVisible === false) {
        delete fields.refNumber;
      }

      return fields;
    }
  },
  methods: {
    submitForm(data) {
      this.$emit('submit', data);
    }
  },
  mounted() {
    this.setFormattedcustomFields(
      this.customFields,
      this.warehouse,
      this.fieldsAreFullWidth ? { width: '100%' } : null
    );
  },
  watch: {
    submissionIterator() {
      this.$refs['details-form'].submitForm();
    }
  }
};
</script>
