var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-sidebar",
    {
      staticClass: "sidebar-component",
      attrs: { gap: "none", "min-content-width": "600px" },
    },
    [
      _c(
        "m-box",
        {
          staticClass: "flex flex-column",
          attrs: { slot: "sidebar", id: "sidebar" },
          slot: "sidebar",
        },
        [
          _c(
            "div",
            { staticClass: "full-height sidebar-content" },
            [
              _c("v-logo"),
              _c(
                "div",
                { staticClass: "logo-tag hide-mobile" },
                [
                  _c(
                    "m-text",
                    {
                      attrs: {
                        variant: "heading-md",
                        as: "p",
                        color: "color-text-inverted",
                      },
                    },
                    [
                      _vm._v(
                        " The industry's leading dock management and scheduling tool "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer", { staticClass: "hide-tablet" }),
          _c("copyright-text", {
            staticClass: "hide-tablet",
            attrs: { color: "color-text-secondary-inverted" },
          }),
          _c("auth-links", {
            staticClass: "hide-tablet",
            attrs: { color: "color-text-inverted" },
          }),
        ],
        1
      ),
      _c(
        "m-box",
        { attrs: { slot: "content", id: "main-content" }, slot: "content" },
        [
          _c(
            "div",
            {
              staticClass:
                "pa-4 d-flex flex-row align-content-center new-sign-in-warning",
            },
            [
              _c("v-icon", { staticClass: "mr-4", attrs: { size: "24px" } }, [
                _vm._v("mdi-bell"),
              ]),
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Welcome to the new Opendock sign in page"),
                ]),
                _c("span", [
                  _vm._v(
                    " You can now use this page to sign in to both the Warehouse Portal and the Scheduling Portal, using the same credentials as before. "
                  ),
                ]),
              ]),
            ],
            1
          ),
          !_vm.emailVerificationData.isEmailVerified
            ? [
                _vm.isLoading
                  ? _c("v-loader", { attrs: { "is-loading": _vm.isLoading } }, [
                      _vm._v("Sending verification email"),
                    ])
                  : _c(
                      "m-stack",
                      {
                        staticClass:
                          "height-100 p-x-4 email-not-verified-container",
                        attrs: { align: "center", justify: "center" },
                      },
                      [
                        _c(
                          "m-stack",
                          { attrs: { gap: "spacing-3", align: "center" } },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "p-b-2",
                                attrs: {
                                  size: "88px",
                                  color: "color-primary-60",
                                },
                              },
                              [_vm._v("email-check")]
                            ),
                            _c(
                              "m-text",
                              { attrs: { variant: "heading-md-bold" } },
                              [_vm._v("Check your e-mail account")]
                            ),
                            _c(
                              "m-text",
                              { attrs: { variant: "body-md", as: "p" } },
                              [
                                _vm._v(
                                  ' Open the message we sent you and click the "Activate my account" button to start using Opendock '
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "m-stack",
                          {
                            staticClass: "p-y-10",
                            attrs: { gap: "spacing-3", align: "center" },
                          },
                          [
                            _c(
                              "m-text",
                              { attrs: { variant: "body-md", as: "p" } },
                              [
                                _vm._v(
                                  " Couldn't find our message? Check your spam folder or resend email "
                                ),
                              ]
                            ),
                            _c(
                              "tertiary-button",
                              {
                                attrs: {
                                  "leading-icon": "refresh",
                                  "test-id": "resend-verification-btn",
                                  size: "small",
                                  block: "",
                                  loading: _vm.isLoading,
                                },
                                on: { click: _vm.sendVerificationEmail },
                              },
                              [_vm._v(" RESEND EMAIL ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "m-stack",
                          {
                            attrs: {
                              gap: "0",
                              align: "center",
                              justify: "center",
                            },
                          },
                          [
                            _c("m-text", { attrs: { variant: "body-md" } }, [
                              _vm._v(
                                "In case you're having any trouble, contact us on:"
                              ),
                            ]),
                            _c(
                              "m-link",
                              {
                                attrs: { href: "mailto:support@opendock.com" },
                              },
                              [_vm._v("support@opendock.com")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ]
            : [
                _c(
                  "m-stack",
                  { attrs: { id: "login-form-container", justify: "center" } },
                  [
                    _c("login-form", {
                      attrs: {
                        "verification-token": _vm.verificationToken,
                        "post-login-route": _vm.postLoginRoute,
                        "is-manage-appointment-login":
                          _vm.isManageAppointmentLogin,
                      },
                      on: {
                        "update:emailVerificationData":
                          _vm.setEmailVerificationData,
                      },
                    }),
                    _c(
                      "m-group",
                      { staticClass: "p-t-4" },
                      [
                        _c("m-text", [
                          _vm._v(" Looking to sign up for our "),
                          _c("strong", [_vm._v("Scheduling Portal")]),
                          _vm._v(" ? "),
                        ]),
                        _c(
                          "v-link",
                          { attrs: { href: "/register", target: "_blank" } },
                          [_vm._v("Register here")]
                        ),
                      ],
                      1
                    ),
                    _c("copyright-text", { staticClass: "hide-desktop" }),
                    _c("auth-links", { staticClass: "hide-desktop" }),
                  ],
                  1
                ),
                _c("reset-password-dialog", {
                  attrs: {
                    "reset-token": _vm.resetToken,
                    email: _vm.resetEmail,
                  },
                  on: {
                    close: function ($event) {
                      _vm.dialogs.resetPassword = false
                    },
                  },
                  model: {
                    value: _vm.dialogs.resetPassword,
                    callback: function ($$v) {
                      _vm.$set(_vm.dialogs, "resetPassword", $$v)
                    },
                    expression: "dialogs.resetPassword",
                  },
                }),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }