import { render, staticRenderFns } from "./DialogBase.vue?vue&type=template&id=176f8229&scoped=true"
import script from "./DialogBase.vue?vue&type=script&lang=js"
export * from "./DialogBase.vue?vue&type=script&lang=js"
import style0 from "./DialogBase.vue?vue&type=style&index=0&id=176f8229&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../apps/luna/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "176f8229",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/repo/apps/luna/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('176f8229')) {
      api.createRecord('176f8229', component.options)
    } else {
      api.reload('176f8229', component.options)
    }
    module.hot.accept("./DialogBase.vue?vue&type=template&id=176f8229&scoped=true", function () {
      api.rerender('176f8229', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "libs/satellite/src/components/v2/elements/dialogs/DialogBase.vue"
export default component.exports