<template>
  <m-group>
    <v-link v-bind="$attrs" size="small" :href="$homePageUrl" target="_blank">
      Opendock Website
    </v-link>
    <v-link v-bind="$attrs" size="small" :href="$termsAndConditionsUrl" target="_blank">
      Terms and Conditions
    </v-link>
    <v-link v-bind="$attrs" size="small" :href="$privacyPolicyUrl" target="_blank">
      Privacy Policy
    </v-link>
  </m-group>
</template>

<script>
import { VLink } from '@satellite/components/v2';
/**
 * Links for opendock, terms and conditions, privacy policy, and more as needed.  The urls are set in Luna's app.store.js
 * @displayName Auth Links
 */
export default {
  name: 'AuthLinks',
  components: { VLink }
};
</script>
