<template>
  <button-base
    variant="danger"
    color="color-text-inverted"
    :test-id="testId"
    v-bind="[$attrs]"
    @click="handleClick($event)">
    <!-- @slot Inner button content -->
    <slot></slot>
  </button-base>
</template>

<script>
import buttonMixin from '@satellite/components/mixins/buttonMixin';
import { ButtonBase } from '@satellite/components/v2';
import testable from '@satellite/components/v2/mixins/testable';

/**
 * Button Base with hard-coded "danger" variant
 * @displayName Error Button
 */
export default {
  name: 'ErrorButton',
  components: {
    ButtonBase
  },
  mixins: [buttonMixin, testable]
};
</script>
