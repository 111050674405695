<template>
  <button type="button" :data-testid="testId" @click="handleClick($event)" :class="{ underlined }">
    <span>
      <!-- @slot Inner button content -->
      <slot></slot>
    </span>
  </button>
</template>

<script>
import buttonMixin from '@satellite/components/mixins/buttonMixin';
import testable from '@satellite/components/v2/mixins/testable';

/**
 * Button that looks like a link
 * @displayName Link Button
 */
export default {
  name: 'LinkButton',
  mixins: [buttonMixin, testable],
  props: {
    underlined: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  /**
   * UNFORTUNATE
   * We cannot apply multiple variants to the miranda m-text component, so we have to manually apply them here
   * because we cannot style in the shadow root scope without applying inline styles
   */
  &:focus {
    span {
      // Miranda focused link color
      color: $m-color-text-link;
    }
  }

  span {
    // Miranda link color
    color: $m-color-text-link;
    font-weight: 700;

    // body-md-underline
    font-family: var(--m-font-family-default, 'Manrope', sans-serif);
    font-size: 14px;
    font-style: normal;
    line-height: 140%; /* 19.6px */
    text-decoration: underline;
  }
  padding: 0;
  margin: 0;
}
</style>
