<template>
  <m-button
    :disabled="isLoading"
    v-bind="[$attrs, $props]"
    v-on="$listeners"
    class="button-base"
    :class="[{ is__block: isBlock, 'width-100': isBlock, 'no-bg': noBg }, `button-${_uid}`]"
    :style="buttonWrapperStyles"
    :data-testid="testId">
    <template v-if="!isLoading">
      <span slot="leading" v-if="leadingIcon">
        <v-icon
          :color="$attrs.disabled ? 'color-text-disabled' : computedColor"
          :size="iconSize ?? $attrs.size">
          {{ leadingIcon }}
        </v-icon>
      </span>
      <!-- @slot Inner button content -->
      <slot></slot>
      <span slot="trailing" v-if="trailingIcon">
        <v-icon
          :color="$attrs.disabled ? 'color-text-disabled' : computedColor"
          :size="iconSize ?? $attrs.size">
          {{ trailingIcon }}
        </v-icon>
      </span>
    </template>
    <div v-else class="p-x-6">
      <div class="dot-flashing"></div>
    </div>
  </m-button>
</template>

<script>
import VIcon from '@satellite/components/v2/elements/basic/VIcon';
import testable from '@satellite/components/v2/mixins/testable';
import SizingEnum from '@satellite/enums/SizingEnum';

/**
 * Button Base wraps Mirdanda WC library's <m-button> component and is used to make variant buttons like <primary-button> and <secondary-button>.
 * All props and attributes are passed through to the Miranda component, so it can be used in the same way as the
 * <m-button> component along with the added functionality of the wrapper
 * @displayName Button Base
 */

export default {
  name: 'ButtonBase',
  mixins: [testable],
  components: { VIcon },
  props: {
    /**
     * MDI icon to display before button content slot.  Can optionally omit 'mdi-' prefix from icon e.g. "mdi-email" or "email" will both work
     */
    leadingIcon: {
      type: String,
      required: false,
      default: null
    },
    /**
     * MDI icon to display before button content slot.  Can optionally omit 'mdi-' prefix from icon e.g. "mdi-email" or "email" will both work
     */
    trailingIcon: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Applies 100% width to component
     */
    isBlock: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Is button in loading state
     */
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Remove color from button
     */
    noBg: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Size of the icons inside the button
     */
    iconSize: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Make button display inline
     */
    isInline: {
      type: Boolean,
      required: false,
      default: false
    },
    // TODO: Document
    color: {
      type: String,
      required: false,
      default: null
    },
    backgroundColor: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    // TODO: Good candidate to abstract to color mixin?
    computedColor() {
      const color = this.$attrs.disabled ? 'color-text-disabled' : this.color;
      return this.mirandaUtil.getTokenCssValue(color) || color;
    },
    computedBackgroundColor() {
      const color = this.backgroundColor;
      return this.mirandaUtil.getTokenCssValue(color) || color;
    },
    computedSize() {
      if (this.$attrs.size && !this.isSizeEnum) {
        const { sizeValue, sizeUnit } = this.util.computeSize(this.$attrs.size);
        return sizeValue && sizeUnit ? `${sizeValue}${sizeUnit}` : null;
      }

      return null;
    },
    isSizeEnum() {
      return Object.keys(SizingEnum).includes(this.$attrs.size);
    },
    buttonWrapperStyles() {
      if (this.computedSize) {
        return `min-block-size:${this.computedSize} !important; block-size:${this.computedSize} !important; inline-size:${this.computedSize} !important; min-inline-size:${this.computedSize} !important`;
      }
      return null;
    }
  },
  mounted() {
    this.$nextTick(() => {
      let buttonStyles = [];
      if (this.noBg) {
        buttonStyles.push('background: transparent !important;border: none !important;');
      }
      if (this.isInline) {
        buttonStyles.push('display:inline !important; width: auto !important;');
      }
      if (this.computedColor) {
        buttonStyles.push(`color:${this.computedColor} !important;`);
      }
      if (this.computedBackgroundColor) {
        buttonStyles.push(`background-color:${this.computedBackgroundColor} !important;`);
      }
      if (buttonStyles.length > 0) {
        this.util.appendStyleToShadowDomEl(
          document.querySelector(`.button-${this._uid}`),
          'button',
          buttonStyles.join()
        );
      }
    });
  }
};
</script>

<style lang="scss" scoped>
// TODO: Make this an official loader outside of this component?
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $m-color-text-tertiary;
  color: $m-color-text-tertiary;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.25s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $m-color-text-tertiary;
  color: $m-color-text-tertiary;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $m-color-text-tertiary;
  color: $m-color-text-tertiary;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0.5s;
}

@keyframes dot-flashing {
  0% {
    background-color: $m-color-text-tertiary;
  }
  50%,
  100% {
    background-color: rgba(92, 106, 118, 0.2);
  }
}
</style>
