<template>
  <m-page-content class="full-vh flex flex-column page-wrapper">
    <page-header is-compact :should-collapse="false" class="auth-bar">
      <template #title>
        <v-logo class="logo-spacing" v-show="true"></v-logo>
        <icon-button
          class="display-tablet"
          test-id="sidebar-trigger-btn"
          no-bg
          icon-size="25px"
          @click="toggleSidebar(true)">
          menu
        </icon-button>
        <v-logo light-mode class="p-l-3 display-tablet"></v-logo>
      </template>
      <template #header-actions>
        <template v-if="$isMrPreview">
          <m-tag variant="warning">Preview Mode</m-tag>
        </template>
        <m-group align="center" v-else>
          <vuetify-menu v-model="showHelpOptions" bottom left v-if="$me">
            <template v-slot:activator="{ on, attrs }">
              <icon-button
                size="20px"
                icon-size="12px"
                icon-color="color-text-inverted"
                background-color="color-primary-60"
                test-id="help-options-btn"
                v-on="on"
                variant="icon">
                help
              </icon-button>
            </template>
            <m-card>
              <m-card-title>
                <m-group class="clickable" role="button">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://community.loadsmart.com/hc/en-us/sections/28818611583891-Opendock-Scheduling-Portal"
                    style="display: contents">
                    <v-icon size="20px">help-circle</v-icon>
                    <m-stack>
                      <m-text variant="body-md-bold">FAQs</m-text>
                    </m-stack>
                  </a>
                </m-group>
              </m-card-title>
              <m-card-body>
                <m-group
                  gap="none"
                  align="center"
                  data-testid="logout-btn"
                  class="clickable"
                  role="button">
                  <div @click="$eventHub.$emit('open-chat-window')">
                    <v-icon size="15px" class="m-r-3 p-t-1">forum</v-icon>
                    <m-text variant="body-md-bold">Support chat</m-text>
                  </div>
                </m-group>
              </m-card-body>
            </m-card>
          </vuetify-menu>

          <vuetify-menu v-model="showUserOptions" bottom left v-if="$me">
            <template v-slot:activator="{ on, attrs }">
              <button-base
                test-id="user-options-btn"
                v-on="on"
                variant="icon"
                color="color-primary-60">
                {{ userInitials }}
              </button-base>
            </template>

            <m-card>
              <m-card-title>
                <m-group gap="spacing-2" class="clickable" @click="viewUserProfile" role="button">
                  <v-icon size="20px">account</v-icon>
                  <m-stack gap="none">
                    <m-text variant="body-md-bold">
                      {{ userFullName }}
                    </m-text>
                    <m-text v-if="$me?.company?.name" variant="body-sm" color="color-text-tertiary">
                      {{ $me.company.name }}
                    </m-text>
                  </m-stack>
                </m-group>
              </m-card-title>
              <m-card-divider class="p-x-2"></m-card-divider>
              <m-card-body>
                <m-group
                  gap="none"
                  align="center"
                  @click="logout"
                  data-testid="logout-btn"
                  class="clickable"
                  role="button">
                  <v-icon size="15px" class="m-r-3 p-t-1">logout</v-icon>
                  <m-text variant="body-md-bold">Logout</m-text>
                </m-group>
              </m-card-body>
            </m-card>
          </vuetify-menu>
        </m-group>
      </template>
    </page-header>

    <!-- @slot Page header -->
    <slot name="header">
      <page-header v-if="!$slots['header']" :title="pageTitle"></page-header>
    </slot>
    <m-body
      ref="page-body"
      id="page-body"
      :class="{
        'allow-overflow': allowOverflow,
        'has-footer': $slots['footer'],
        'has-sidebar': hasSidebar
      }">
      <section class="page-body-content">
        <!-- @slot Page body -->
        <slot name="body"></slot>
      </section>
      <section class="page-footer" ref="footer">
        <!-- @slot Page footer -->
        <slot name="footer"></slot>
      </section>
    </m-body>
  </m-page-content>
</template>

<script>
import { PageHeader, IconButton, ButtonBase, VIcon, VLogo } from '@satellite/components/v2';
import { VMenu as VuetifyMenu } from 'vuetify/lib/components';
import { upperFirst } from '@satellite/../nova/core';

/**
 * HOC for internal pages
 * @displayName Internal Page
 */
export default {
  name: 'InternalPage',
  components: {
    PageHeader,
    IconButton,
    VuetifyMenu,
    ButtonBase,
    VIcon,
    VLogo
  },
  props: {
    /**
     * Title of the page - optionally used in place of the title slot
     */
    pageTitle: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Allow scrolling of the body content
     */
    allowOverflow: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Controls the placement of the footer and accounts for the sidebar width
     * This will not typically be used, but it's here for internal pages that may hide the sidebar
     */
    hasSidebar: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    userInitials() {
      let initials;
      if (this.$me?.id) {
        initials = `${this.$me.firstName.charAt(0)}${this.$me.lastName.charAt(0)}`.toUpperCase();
      }
      return initials;
    },
    userFullName() {
      let name;
      if (this.$me?.id) {
        name = `${upperFirst(this.$me.firstName)} ${upperFirst(this.$me.lastName)}`;
      }
      return name;
    }
  },
  data() {
    return {
      // See setBottomPadding method
      initialPaddingBottom: 24,
      showUserOptions: false,
      showHelpOptions: false
    };
  },
  methods: {
    upperFirst,
    styleBodyWrapper() {
      this.util.appendStyleToShadowDomEl(
        this.$refs['page-body'],
        '.body-wrapper',
        `height: 100%;padding-bottom:0 !important;box-sizing:border-box;overflow:${
          this.allowOverflow ? 'auto' : 'hidden'
        }`
      );
    },
    /**
     * This is somewhat gross, but it's the only way we can absolutely position the footer and also allow for consistent
     * padding between the bottom of the page and the page content
     * @param footerHeight
     */
    setBottomPadding(footerHeight = 0) {
      this.$refs['page-body'].style.paddingBottom = `${this.initialPaddingBottom + footerHeight}px`;
    },
    logout() {
      this.$store.dispatch('Auth/logout');
      this.$router.replace({ name: 'login' });
    },
    async viewUserProfile() {
      await this.$router.push({ name: 'settings' });
    },
    toggleSidebar(val) {
      this.$store.commit('App/setIsSidebarOpen', val);
    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.styleBodyWrapper();
    });
  },
  watch: {
    results: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.styleBodyWrapper();
          });
        }
      },
      immediate: true
    },
    '$slots.footer': {
      handler() {
        /**
         * Once again, this seems gross, but it needs 2 render cycles before the element targeted here is fully rendered
         * If we try to access after first `tick`, the height is 0.
         */
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.setBottomPadding(this.$refs.footer.clientHeight);
          });
        });
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.body-wrapper {
  height: 100%;
}

.page-wrapper {
  overflow: auto;
  position: relative;
}

.v-menu__content {
  transform: translateY(35px);
  min-width: 250px !important;
}

.logout-action {
  &:hover {
    cursor: pointer !important;
  }
}

.auth-bar {
  padding-top: 3px;
  padding-bottom: 3px;
}

.logo-spacing {
  visibility: hidden;
  ::v-deep {
    img {
      height: 20px;
    }
  }

  @media (max-width: $tabletBreakpoint) {
    display: none;
  }
}

#page-body {
  background: $m-color-background-secondary;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;
  flex: 1;
  padding-bottom: $m-spacing-6;
}

.page-body-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1280px;
  margin: auto;
}

.page-footer {
  position: absolute;
  background-color: #fcfcff;
  border-top: 1px solid var(--line-divider, #e6edf2);
  bottom: 0;
  left: 0;
  width: 100%;

  &.has-sidebar {
    left: calc(#{$internalSidebarWidth} + 2px);
    width: calc(100% - #{$internalSidebarWidth} - 2px);
  }

  @media (max-width: $smallDesktopBreakpoint) {
    &.has-sidebar {
      left: 0;
      width: 100%;
    }
  }
}
</style>
