var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-stack",
    {
      staticClass: "flex-1 height-100",
      class: { "is-loading": _vm.isLoading },
    },
    [
      _c(
        "m-group",
        {
          staticClass: "header",
          style: `background-color:${_vm.computedHeaderBgColor}`,
          attrs: { align: "center", justify: "space-between" },
        },
        [
          _vm.docks && _vm.allowDockSelect
            ? _c("drop-down-field", {
                attrs: {
                  "test-id": "dock-select",
                  label: "Docks",
                  options: _vm.docks.map((dock) => ({
                    label: dock.name,
                    value: dock.id,
                  })),
                },
                model: {
                  value: _vm.selectedDock,
                  callback: function ($$v) {
                    _vm.selectedDock = $$v
                  },
                  expression: "selectedDock",
                },
              })
            : _c("m-text", { attrs: { variant: "heading-sm-bold" } }, [
                _vm._v("Select date and time"),
              ]),
          _c("date-nav", {
            class: { "p-t-4": _vm.docks },
            attrs: {
              "is-previous-button-disabled": _vm.isPrevDisabled,
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate,
            },
            on: { prev: _vm.loadPrevDateRange, next: _vm.loadNextDateRange },
            model: {
              value: _vm.selectedDate,
              callback: function ($$v) {
                _vm.selectedDate = $$v
              },
              expression: "selectedDate",
            },
          }),
        ],
        1
      ),
      _c(
        "m-grid",
        { attrs: { id: "availability-grid" } },
        [
          _vm._l(_vm.dateRange, function (item, index) {
            return _c(
              "m-group",
              {
                key: `slot-${item.date}-header`,
                staticClass: "date-header",
                class: `flex-order-${2 * index}`,
                attrs: { gap: "none", justify: "center", align: "center" },
              },
              [
                _c(
                  "m-stack",
                  { attrs: { align: "flex-start", gap: "none" } },
                  [
                    _c("m-text", { attrs: { variant: "body-md" } }, [
                      _vm._v(_vm._s(item.day)),
                    ]),
                    _c("m-text", { attrs: { variant: "heading-sm-bold" } }, [
                      _vm._v(_vm._s(item.date)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          !_vm.isLoading
            ? _vm._l(_vm.dateRange, function (item, index) {
                return _c(
                  "m-stack",
                  {
                    key: `slot-${item.date}-times`,
                    staticClass:
                      "text-center time-column d-flex flex-column align-center slots",
                    class: `flex-order-${index + (index + 1)}`,
                    attrs: { gap: "spacing-2" },
                  },
                  [
                    Boolean(
                      _vm.availability[item.date] &&
                        _vm.availability[item.date].length
                    )
                      ? [
                          _vm._l(
                            _vm.availability[item.date],
                            function (slot, i) {
                              return [
                                _c(
                                  "secondary-button",
                                  {
                                    key: `${i}-time`,
                                    staticClass: "time-button",
                                    class: {
                                      active: _vm.isSlotSelected(slot.start),
                                    },
                                    attrs: { "test-id": slot.start.toISO() },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSlotClick(slot)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatTime(slot.start.toISO())
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            }
                          ),
                        ]
                      : [_c("div", [_vm._v("No availability")])],
                  ],
                  2
                )
              })
            : _vm._e(),
        ],
        2
      ),
      _vm.isLoading
        ? _c(
            "m-group",
            {
              staticClass: "flex-1",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c("v-loader", { attrs: { "is-loading": _vm.isLoading } }, [
                _vm._v("Loading availability"),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }