<template>
  <m-stack gap="spacing-1">
    <template v-if="hasSeparateLabel">
      <m-text variant="body-md" color="color-text-tertiary">{{ label }}</m-text>
      <m-group align="center" class="flex-nowrap" gap="spacing-4">
        <template v-if="!$slots.value">
          <m-text variant="body-md" v-if="computedValue" v-html="computedValue"></m-text>
          <no-content v-else></no-content>
        </template>
        <template v-else>
          <!-- @slot custom value content -->
          <slot name="value"></slot>
        </template>
        <copy-content-button
          v-if="copyContent"
          :test-id="`copy-${toKebabCase(label.toLowerCase())}-btn`"
          :message="copyMessage"
          :content="copyContent ?? value" />
      </m-group>
    </template>
    <template v-else>
      <m-group align="flex-start" class="flex-nowrap" gap="spacing-4">
        <m-stack gap="spacing-1">
          <m-text variant="body-md" color="color-text-tertiary">{{ label }}</m-text>
          <template v-if="!$slots.value">
            <m-text variant="body-md" v-if="computedValue" v-html="computedValue"></m-text>
            <no-content v-else></no-content>
          </template>
          <template v-else>
            <!-- @slot custom value content -->
            <slot name="value"></slot>
          </template>
        </m-stack>

        <copy-content-button
          v-if="copyContent"
          :test-id="`copy-${toKebabCase(label.toLowerCase())}-btn`"
          :message="copyMessage"
          :content="copyContent ?? value" />
      </m-group>
    </template>
  </m-stack>
</template>
<script>
import { CopyContentButton, NoContent } from '@satellite/components/v2';
import { toKebabCase, sanitizeInput } from '@satellite/../nova/core';
import { isArray } from 'class-validator';
import { isNull } from 'lodash';
// TODO: Cleanup duplicate code
/**
 * Field Value - used to display a key/value in a stacked format
 * @displayName Field Value
 */
export default {
  name: 'FieldValue',
  components: {
    NoContent,
    CopyContentButton
  },
  props: {
    /**
     * Field label
     */
    label: {
      type: String,
      required: true
    },
    /**
     * Optional field value - can use slot if more control is needed
     */
    value: {
      type: [String, Array, Number, Boolean],
      required: false,
      default: null
    },
    /**
     * Content to copy to clipboard
     */
    copyContent: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Copy success message once content is copied to clipboard
     */
    copyMessage: {
      type: String,
      required: false,
      default: 'Copied to clipboard'
    },
    /**
     * This determines if the content is in a separate group than the label which mainly affects the copy button positioning
     */
    hasSeparateLabel: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    computedValue() {
      let val = this.value;
      if (typeof this.value === 'object' && isArray(this.value)) {
        val = this.value.join(', ');
      }
      if (typeof val === 'string' && val.length === 0) {
        val = null;
      }
      return !isNull(val) && typeof val !== 'undefined' ? sanitizeInput(val) ?? null : val;
    }
  },
  methods: {
    isNull,
    toKebabCase
  }
};
</script>
