<template>
  <m-field
    :data-testid="testId"
    class="text-field-wrapper flex-1"
    :class="`text-area-${_uid}`"
    :status="hasError ? 'danger' : 'default'">
    <m-field-label v-if="label" slot="label" :data-testid="`${testId}-label`">
      {{ label }}
    </m-field-label>
    <m-text-area
      v-on="{
        ...$listeners,
        input: event => (internalValue = event.target.value)
      }"
      resize="none"
      :style="`${computedWidth};max-width: 100%;`"
      :class="{ 'is-compact': isCompact }"
      v-bind="[$attrs]"
      :name="fieldName"
      :placeholder="placeholder"
      :value="lazyValue"
      @focus="handleFocus"
      @blur="handleBlur"></m-text-area>
    <m-field-hint :data-testid="`${testId}-errors`">
      <span>{{ errorBucket.join(', ') }}</span>
      <span v-if="errorBucket.length && hint">.</span>
      <span v-if="hint" :class="{ 'p-l-1': errorBucket.length }">{{ hint }}</span>
    </m-field-hint>
  </m-field>
</template>

<script>
import fieldMixin from '@satellite/components/v2/mixins/fieldMixin';
import validatable from '@satellite/components/v2/mixins/validatable';
import testable from '@satellite/components/v2/mixins/testable';

export default {
  name: 'TextArea',
  mixins: [fieldMixin, testable, validatable],
  props: {
    /**
     * @model
     */
    value: {
      type: String,
      required: false
    },
    /**
     * Sets height of textarea to more compact size
     */
    isCompact: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    if (this.isCompact) {
      this.util.appendStyleToShadowDomEl(
        document.querySelector(`.text-area-${this._uid} > m-text-area`),
        'textarea',
        'min-height:50px;max-height:50px;min-block-size:50px !important;'
      );
    }
  }
};
</script>

<style lang="scss" scoped>
m-text-area.is-compact {
  min-height: 60px;
  max-height: 60px;
}
</style>
